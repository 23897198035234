import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import InputField from './inputfield';
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/Site-Rarites_Image.png";
import i2 from "./assets/images/logo.png";
import i4 from "./assets/images/ezgif.com-gif-maker.gif";
import i5 from "./assets/images/HeroImg.png";
import i6 from "./assets/images/icons/twitter-svgrepo-com.svg";
import i7 from "./assets/images/icons/discord.png";

export const StyledButton = styled.button`
  padding: 20px 15px!important;
  border-radius: 10px;
  font-size:18px;
  border: none;
  background-color: orange;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 250px;
  cursor: pointer;
  box-shadow: 0 0 5px #ccc;
  :active {
    box-shadow: 0 0 5px #000;
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;



const Container = styled.div`
  text-align: center;
  width:100%;
  max-width:400px;
  `;


export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  @media (min-width: 767px) {
    width: 100%;
    height: 100%;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);
  //const quantity = useRef(null);
  const [quantity, setQuantity] = useState('1');
  //const [gasFee, setGasFee] = useState("13316913");
  //var gasFee = useRef("285000");

  function checkQuantity(value) {
      if (value > 10){
        setFeedback("You Can Only Mint 10 Bagz");
      }else{
      setQuantity(value);
      }
  }
 

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Crypto Money Bagz...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: '285000',
        to: "0x22d0cce5d33a4d0af39379767ab48af4cb4c0bde",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.035 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");

        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "You now own a Crypto Money Bagz. Go visit Opensea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--white)", backgroundImage: 'url("/assets/images/279v2-5.png")' }}>
       <s.Container flex={1} ai={"center"} style={{ width: "100%"}}>
       <header id="main-header">
      <div class="row">
        <div class="small-11 large-6 columns"><a href="https://cryptomoneybagz.com"><StyledImg alt={"example"} src={i2} style={{width: 300, height:'100%'}} className='logo'/></a></div>
        <nav id="main-nav" class="small-16 large-10 columns">
          <ul class="main-menu">
            <li><a href="#what">What is CMB?</a></li>
            <li><a href="#rare"> Rarities</a></li>
            <li><a href="#roadmap">Roadmap</a></li>
            <li><a href="https://twitter.com/moneybagznft"><StyledImg alt={"example"} className="navIcon" src={i6} style={{width:30, height:30, marginRight:10, color:'#fff', float:"left", padding: '-4px 0 0 0'}}/> </a></li>
          </ul>
          <ul class="mobile-menu"></ul>
        </nav>
      </div>

      </header>
         </s.Container>

         <s.Container flex={1} ai={"center"} style={{ width: "100%"}} className="mainCMB">
         <section id="home-slider">
      <div id="sliderBack">
        <div class="row">
          <div class="small-16" align="center">
        <StyledImg alt={"example"} src={i5} ai={"center"} className="heroImg"/>
        <s.TextDescription className="mintTxtTop">Minting Begins On:</s.TextDescription>
        <s.TextDescription className="mintTxtBtm">September 27th @ 1PM EST</s.TextDescription>
        <s.TextDescription className="mintTwitterBtn"><a href="https://twitter.com/moneybagznft"><StyledImg alt={"example"} src={i6} style={{width:30, height:30, marginRight:10, color:'#fff!important', padding: '-4px 0 0 0'}}/> Follow Us On Twitter</a></s.TextDescription>
        </div>
        </div>
      </div>
</section>
           </s.Container>

      <s.Container flex={1} ai={"center"}  className="mintCMB" style={{ width: "100%"}}>
      <section id="home-slider">
      <div class="row">
        <ResponsiveWrapper flex={1} >
          <s.Container flex={1} jc={"center"} ai={"center"} className="mintLeftSide">
          <StyledImg alt={"example"} src={i5} ai={"center"} className="heroImgMint"/>
    

            <s.TextTitle
              style={{fontFamily: 'Ariston', textAlign: "center", fontSize: 45, fontWeight: "normal", color: "#000" }}
            >
              {data.totalSupply}/10000
            </s.TextTitle>
         
          </s.Container>
          <s.SpacerMedium />
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            
            className="mintRightSide"
          >
            {Number(data.totalSupply) === 10000 ? (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "#000", fontSize:30, fontWeight:'bold' }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center", color: "#000",fontFamily: 'Ariston', textAlign: "center", fontSize: 65, lineHeight:1 }}>
                  You can still find Crypto Money Bagz on{" "}
                  <a href="_blank" to="https://opensea.io/collection/ncrypto-money-bagz" style={{fontFamily: 'Ariston', textAlign: "center", fontSize: 65,}}>
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle
          style={{ fontFamily: 'Ariston', textAlign: "center", fontSize: 65, fontWeight: "normal", color: "yellow", textShadow:'0 0 5px darkblue', lineHeight: '50px' }} className="mintBagsTitle"
        >
          Mint Your Crypto Money Bagz
        </s.TextTitle>
                <s.TextTitle style={{fontFamily: 'Ubuntu', textAlign: "center", fontSize: 30, fontWeight: 'bold', color: "#000"}}>
                  1 CMB costs 0.035 ETH.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center", fontSize: 20, color: "#000" }}>
                  Excluding gas fee.
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center", fontSize: 20, fontWeight: 'bold', color: "#000", backgroundColor: 'yellow', padding: '10px' }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"} >
                    <s.TextDescription style={{ textAlign: "center", fontSize: 16, color: "#000" }}>
                      Connect to the Ethereum network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT WALLET
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center", color: "#000", fontSize: 16 }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                        
                      </>
                    ) : null}
                  </s.Container>
                ) : (

               
                  <s.Container ai={"center"} jc={"center"}  >
                 <Container>
                 <input
                  label={'Quantity'}
                  name='quantity'
                  onChange={e => checkQuantity(e.target.value)}
                  value={quantity}
                  //onChange={setQuantity}
                             /> 
                             </Container>
                             <Container>
                    <StyledButton
                      disabled={claimingNft ? quantity : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(quantity);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "MINT " + quantity}
                    </StyledButton>
                             </Container>

                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        {Number(data.totalSupply) === 10000 ? (<></>
) : (          
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
          <s.TextDescription style={{ textAlign: "center", fontSize: 14, color: "#000", padding:'0 10px' }}>
            Please make sure you are connected to the right network (Ethereum
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
     
        </s.Container>
        
        )}
        </div>
        </section>
      </s.Container>

      <s.Container flex={1} ai={"center"} style={{ width:'100%'}} id="what">
      <div class="content-top"></div>
      <section id="main-content">
      <div class="row">
      <div class="small-16 large-8 columns" style={{marginBottom:30}}>
      <StyledImg alt={"example"} src={i4} />
        </div>
        <div class="small-16 large-8 columns">
          <article class="about-us-summary">
            <h2>What is Crypto Money Bagz?</h2>
            
            <s.TextDescription style={{  textAlign: "left", fontSize: 25, lineHeight: 1.5, fontWeight: 'bold', color: "#fff", marginBottom: 20, marginTop: 20 }}>
            Crypto Money Bagz is a collection of 10,000 randomly generated Money Bag NFTs that represent what everyone is looking to do. Strike it RICH in the crypto market!!!!
            </s.TextDescription>
            <s.TextDescription style={{  textAlign: "left", fontSize: 18, color: "#fff", marginBottom: 20 }}>
              Each Crypto Money Bagz NFT is a unique combination of money bagz, and accompanying backgrounds and other rarities related to the crypto markets top 20 coins based on market cap.
</s.TextDescription>
<s.TextDescription style={{ textAlign: "left", fontSize: 18, color: "#fff", marginBottom: 20 }}>
There will be 10,000 NFTs minted that will live on the Ethereum blockchain, and will cost 0.035 ETH for each NFT minted excluding gas fee.
</s.TextDescription>
<s.TextDescription style={{  textAlign: "left", fontSize: 18, color: "#fff", marginBottom: 20  }}>
Crypto Money Bagz will be giving back to it's supporters by sending 1 ETH to 15 randomly selected holders at 60%. 
</s.TextDescription>
<s.TextDescription style={{  textAlign: "left", fontSize: 18, color: "#fff", marginBottom: 20  }}>
We will be donating 20% of sales to <a href="https://unbound.org">unbound.org</a>, and then continue to donate 20% of total royalty income to them.
</s.TextDescription>
<s.TextDescription style={{  textAlign: "left", fontSize: 18, color: "#fff", marginBottom: 20  }}>
We will raffling off $300,000 to 1 holder 48hrs after we sellout.
</s.TextDescription>




          
          
          </article>
        </div>
      </div>
      </section>
      <div class="content-bottom-img"></div>
</s.Container>

<s.Container flex={1} ai={"center"} style={{ width:'100%', paddingTop: 50, paddingBottom: 50}} id="rare">

 
  <div class="row">
      <div class="small-16 large-8 columns" style={{marginBottom: 30}}>
      <article class="about-us-summary">
      <h2 style={{color:'#000'}}>How Rare is My Money Bag?</h2>
      <s.TextDescription style={{  textAlign: "left", fontSize: 20, lineHeight: 1.5, color: "#000", marginBottom: 20 }}>
      Every money bag is created by a random algorithm which ensures that each money bag is unique and differentiated from the others. Some money bagz have very rare traits that will set them apart from the rest of the bags.
        </s.TextDescription>
              <div class="rarities">
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>10</span> Crypto Terms </s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>20</span> Falling Coins</s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>20</span> Coin Symbols </s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>10</span> Coin Stacks </s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>12</span> Bag Ties</s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>20</span> Bag Symols</s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>16</span> Bags</s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>5</span> Background Designs</s.TextDescription>
              <s.TextDescription style={{color:'#000', fontSize:20, lineHeight: 1.5}}><span style={{fontFamily: 'Ariston', fontSize:30}}>18</span> Backgrounds</s.TextDescription>

              </div>

     </article>
        </div>
        <div class="small-16 large-8 columns">
        <StyledImg alt={"example"} src={i1} />
          </div>
          </div> </s.Container>

          <s.Container flex={1} ai={"center"} style={{ width:'100%'}} id="what">
      <div class="content-top-bottom"></div>
      <section id="main-content">
      <div class="row">
        <div class="small-16 columns">
        <div class="small-16" >
                <s.TextDescription className="newCallOut"><span style={{color:'yellow'}}>We have partnered with <a style={{fontFamily:'sans-serif', textDecoration:'underline'}} href="https://cryptopiggybanks.xyz/">Crypto Piggy Banks</a></span> to utilize our Money Bagz with there NFT Game</s.TextDescription>
          
                </div>
          <article class="about-us-summary" style={{padding:24}}>
            <h2 align="center">Roadmap</h2>
            <div class="small-16" className="rareBoxes">
            <div class="row" style={{marginTop:50}}>
              <div class="small-16 large-2 columns">
              <span class="rareNum">20%</span>
                </div>
                <div class="small-16 large-14 columns">
<s.TextDescription className="rareBox" >
  <ul>
    <li>
10 holders selected randomly and airdroped 1 Crypto Money Bagz.</li><li> 5 holders selected randomly and sent $500 in ETH each.</li></ul></s.TextDescription>
                </div>
              </div>
              <div class="row">
              <div class="small-16 large-2 columns">
              <span class="rareNum" >40%</span>
                </div>
                <div class="small-16 large-14 columns">
                <s.TextDescription className="rareBox">
                <ul>
    <li>
10 more holders selected randomly and airdrop 1 Crypto Money Bagz. </li><li>5 holders selected randomly and sent $1000 in ETH each.</li></ul></s.TextDescription>
                </div>
              </div>
              <div class="row">
              <div class="small-16 large-2 columns">
              <span class="rareNum">60%</span>
                </div>
                <div class="small-16 large-14 columns">
                <s.TextDescription className="rareBox"><ul>
                  <li>
5 holders selected randomly and sent 1 ETH each and 1 Crypto Money Bagz NFT.</li>
<li>20% will be added to development wallet for future project plans</li></ul>
</s.TextDescription>
                </div>
              </div>
              <div class="row">
              <div class="small-16 large-2 columns">
              <span class="rareNum">80%</span>
                </div>
                <div class="ssmall-16 large-14 columns">
                <s.TextDescription className="rareBox">
                  <ul>
                    <li>
10 holders selected randomly and sent 1 ETH and sent 1 ETH each and 1 Crypto Money Bagz NFT.
</li>
<li>20% will be added to development wallet for future project plans</li>
<li>We will buy back 500 Money Bagz to keep the floor high</li></ul>
</s.TextDescription>
                </div>
              </div>
              <div class="row">
              <div class="small-16 large-2 columns">
              <span class="rareNum">100%</span>
                </div>
                <div class="small-16 large-14 columns">
                <s.TextDescription className="rareBox"><ul><li>
We will donate 20% of the sales and 20% of royalty income to <a href="https://unbound.org/" target="_blank">unbound.org</a></li>
<li>1 Holder will be selected selected randomly and win $300,000 48hrs after we sell out and we will buy back 1000 Money Bagz to keep the floor price high</li></ul></s.TextDescription>
          
                </div>

               
              </div>
             
              </div>
          </article>
          <article class="about-us-summary" style={{padding:24}}>
            <h2 align="center">Earn With Your Crypto Money Bagz</h2>
            <div class="row">
                <div class="small-16">
                <s.TextDescription style={{fontSize: '18px', lineHeight:'1.5rem'}}>
                  <ul style={{listStyle:'number'}}>
                    <li style={{margin:'20px 0'}}> When we sellout we will begin development on wealth building also called breeding. This is where you will be able to combine 2 Crypto Money Bagz and create a new Money Bag that you will be able to stake and earn passive income. </li>

                <li>Utilize your Money Bagz NFT in the Crypto Piggies Banks game, where you will be able to give your Piggies special ability more power by connecting your CMB to your piggie. This will give you an edge over the other piggies you are racing and give your piggie a better chance of winning cash prizes.</li></ul></s.TextDescription>
          
                </div>

               
              </div>
            </article>
        </div>
      </div>
      </section>
      <div class="content-bottom-img"></div>
</s.Container>


<s.Container>
  <div class="row">
<div class="small-16" style={{padding: "20px", textAlign: "center", margin: '0 auto'}}>
<s.TextDescription><a href="https://cryptomoneybagz.com"><StyledImg alt={"example"} src={i2} style={{width: 300, height:'100%'}} className='logo'/></a></s.TextDescription>
<s.TextDescription style={{textAlign:'center', margin:'0 auto', display:'block'}}><a href="https://twitter.com/moneybagznft"><StyledImg alt={"example"} src={i6} style={{width:30, height:30, marginRight:10, color:'#fff!important', padding: '-4px 0 0 0'}}/></a></s.TextDescription>
<s.TextDescription style={{fontSize: 16, color: '#000', padding:20, textAlign:'center'}}>&copy; 2021 Crypto Money Bagz</s.TextDescription>
</div>
</div>
</s.Container>

    </s.Screen>
  );
}

export default App;
